import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';

const API = () => {
    const axiosheaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    const axiosheadersfiles = {
        'Content-Type': 'multipart/form-data',
    };
    const serverbaselinkfunc = () => {
        var serverbaselinktemp = 'https://2ve4kp3gmr.us-east-1.awsapprunner.com';
        // var serverbaselinktemp = 'https://corridortalks-a95142e73811.herokuapp.com';
        // var serverbaselinktemp = 'https://apmedgo.com';
        // var serverbaselinktemp = 'http://0.0.0.0:8000';
        // var serverbaselinktemp = 'http://192.168.1.106:8000';
        // var serverbaselinktemp = 'http://localhost:8000';
        // var serverbaselinktemp = 'http://192.168.1.120:8000';
        return serverbaselinktemp;
    };

    const Checkauth_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/userprofiles/UserAuthorizationChecker/',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const Login_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/userprofiles/login/',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const CompaniesSearch_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/companies/companies',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchCompanyReviews_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/reviews/reviews',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchCompanyInterviews_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/interviews/interviews',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchDepartments_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/departments/departments',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const DepartmentsMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/departments/departments',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const EmpqaanswerMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/qa/emp_qaanswer',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const DeleteEmpqaanswerMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/qa/emp_qaanswer',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const DepartmentsDelete_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/departments/departments',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchSalaries_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/salaries/salaries',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchBenefits_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/benefits/benefits',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchUsers_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/userprofiles/fetchusers',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchPhotos_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/officephotos/officephotos',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const SalariesStatusMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/salaries/status',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const BenefitsStatusMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/benefits/status',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const ReviewsStatusMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/reviews/status',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const ReviewsPinMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/reviews/pinreview',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const ReviewsEmployeeCommentMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/reviews/emp_comments',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const ReviewsFlagMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/reviews/flag',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const AnswerFlagMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/qa/flaganswer',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const QuestionFlagMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/qa/flagquestion',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const InterviewsStatusMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/interviews/status',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const PhotosStatusMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/officephotos/status',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchCompanyInfo_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/companies/company',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const CollectionMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/rewards/collections',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const CollectionDelete_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/rewards/collections',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchRewardsCollection_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/rewards/collections',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const RewardsMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/rewards/rewards',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const RewardsDeleteMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/rewards/rewards',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchRewards_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/rewards/rewards',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const CompanyMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/companies/companies',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const CompanyDeleteMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/companies/companies',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const AdduserMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/userprofiles/adduser',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const DeleteuserMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/userprofiles/adduser',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const UpdateuserMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/userprofiles/updateaccountinfo',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const DeleteReview_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/reviews/reviews',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const DeleteBenefit_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/benefits/benefits',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const DeleteInterview_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/interviews/interviews',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const DeleteOfficephotos_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/officephotos/officephotos',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const DeleteSalary_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/salaries/salariescompanies',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const UpdatecompanylogoMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/companies/companylogo',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchIndustries_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/settings/industiries',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const IndustriesMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/settings/industiries',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const IndustriesDelete_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/settings/industiries',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchCompanySizes_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/settings/company_size',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const CompanySizesMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/settings/company_size',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const CompanySizesDelete_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/settings/company_size',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchSkills_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/settings/skills',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const SkillsMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/settings/skills',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const SkillsDelete_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/settings/skills',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchLocations_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/settings/locations',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const LocationsMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/settings/locations',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const LocationsDelete_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/settings/locations',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchJobs_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/departments/jobs',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const JobsMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/departments/jobs',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const JobsDelete_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/departments/jobs',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchPaidfairly_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/salaries/areyoupaidfairly_settings',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const PaidfairlyMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/salaries/areyoupaidfairly_settings',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const PaidfairlyDelete_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/salaries/areyoupaidfairly_settings',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchContribuionSettings_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/settings/contributionsettings',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchPaidfairlycontributions_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/salaries/areyoupaidfarily_contriubtions',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const ContribuionSettingsMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/settings/contributionsettings',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchInterns_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/userprofiles/interns',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const AddInternMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/userprofiles/interns',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const DeleteInternMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/userprofiles/interns',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const UpdateInternMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'patch',
            url: serverbaselinkfunc() + '/api/userprofiles/interns',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchCVs_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/cv/allcvs',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchCVRequestss_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/cv/allhrcvrequests',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const fetchsuggestedcompanies_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/companies/suggestcompany',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const updateshowinHomesettingsMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/settings/updateshowinhomesettings',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const assigncontribcompanyview_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/settings/assigncontribcompany',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchReviewComment_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/reviews/comments',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const DeleteReviewComment_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/reviews/comments',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const fetchAvaliableJobs_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/jobs/jobs',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const AddAvaliableJobMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/jobs/jobs',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const UpdateAvaliableJobMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'patch',
            url: serverbaselinkfunc() + '/api/jobs/jobs',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const DeleteAvaliableJobMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/jobs/jobs',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const fetchJobPhases_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/jobs/phases',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const AddJobPhaseMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/jobs/phases',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const UpdateJobPhaseMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'patch',
            url: serverbaselinkfunc() + '/api/jobs/phases',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const DeleteJobPhaseMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/jobs/phases',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchQuestions_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/qa/qa',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchAnswer_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/qa/qaanswer',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const EmpcompanypdatemaininfoMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/companies/emp_company_updatemaininfo',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const BycompanybenefitsMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/companies/by_company_benefits',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const CompanyupdateofficesMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/companies/company_updateoffices',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const DeleteCompanyupdateofficesMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/companies/company_updateoffices',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const CompanyupdatevideolinkMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/companies/emp_company_videos',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const CompanyupdatesocialmedialinksMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/companies/company_updatesocialmedialinks',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const EmpcompanynamechangerequestMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/companies/emp_company_namechangerequest',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchEmpcompanyleadership_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/companies/emp_company_leadership',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchEmployerfaq_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/settings/employer_faq',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchCompaniesfollowers_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/companies/follow',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const EmployerteamMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/employerteam/',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const EmployerteamEditMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'patch',
            url: serverbaselinkfunc() + '/api/employerteam/',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const EmpcompanyinvitecompanyemployeeMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/companies/emp_company_invitecompanyemployee',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const EmployerteamDeleteMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/employerteam/',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const DeleteEmpcompanyleadershipMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/companies/emp_company_leadership',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    // const ChangePhaseMutation_API = async (axiosdata) => {
    //     var tempaxiosdata = { ...axiosdata };
    //     axios.defaults.withCredentials = true;
    //     const axiosfetch = axios({
    //         method: 'post',
    //         url: serverbaselinkfunc() + '/api/jobs/applicantphase',
    //         headers: axiosheaders,
    //         data: tempaxiosdata,
    //     });
    //     return axiosfetch;
    // };
    const FetchFaculties_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/settings/faculities',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const ChangePhaseMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/jobs/applicantphase_bulk',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const DeleteApplicantMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/jobs/applicantphase',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchEmployerjobrejectreason_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/settings/employer_job_rejectreason',

            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const ApplicantscommentsMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/jobs/applicantscomments',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchApplicantscomments_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/jobs/applicantscomments',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchEmployerteam_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/employerteam/',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchQuestionaire_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/jobs/questionaire',

            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const QuestionaireMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/jobs/questionaire',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const DeleteQuestionMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/jobs/questionaire',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const EmpcompanyextrafieldsMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/companies/emp_company_extrafields',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const EmpcompanybycompanyawardsMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/companies/emp_company_bycompanyawards',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchEmpcompanymissingfields_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/companies/emp_company_missingfields',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchAtsgenview_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/jobs/ats_genview',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchAtsphaseview_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/jobs/ats_phaseview',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchInsights_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/companies/emp_company_insights',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const DeleteAnswer_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/qa/qaanswer',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchHomeInsights_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/companies/emp_company_home_insights',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchCompanyBadges_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/companies/emp_company_badges',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    // const UnlockapplicantMutation_API = async (axiosdata) => {
    //     var tempaxiosdata = { ...axiosdata };
    //     axios.defaults.withCredentials = true;
    //     const axiosfetch = axios({
    //         method: 'post',
    //         url: serverbaselinkfunc() + '/api/jobs/unlockapplicant',
    //         headers: axiosheaders,
    //         data: tempaxiosdata,
    //     });
    //     return axiosfetch;
    // };

    const UnlockapplicantMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/jobs/unlockapplicant_bulk',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const deleteOfficephotosMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/companies/emp_company_officephotos',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const Fetchapplicant_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/jobs/fetchapplicant',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchBadges_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'get',
            url: serverbaselinkfunc() + '/api/settings/badges',

            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const DeleteIntervieweviewComment_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'delete',
            url: serverbaselinkfunc() + '/api/interviews/comments',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const AddInterviewLike_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/interviews/likes',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const AddReviewLike_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/reviews/likes',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchIntervieweviewComment_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/interviews/comments',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const AddIntervieweviewComment_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/interviews/comments',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };

    const ReviewsgroupedbyjobsView_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/reviews/reviews_groupedbyjobsView',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const DuplicateJob_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/jobs/duplicate',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const changePasswordMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/userprofiles/changepassword',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    // const notifyapplicantMutation_API = async (axiosdata) => {
    //     var tempaxiosdata = { ...axiosdata };
    //     axios.defaults.withCredentials = true;
    //     const axiosfetch = axios({
    //         method: 'post',
    //         url: serverbaselinkfunc() + '/api/jobs/notifyapplicant',
    //         headers: axiosheaders,
    //         data: tempaxiosdata,
    //     });
    //     return axiosfetch;
    // };
    const notifyapplicantMutation_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/jobs/notifyapplicant_Bulk',
            headers: axiosheaders,
            data: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchReviewsinsights_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/reviews/emp_reviewsinsights',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    const FetchInterviewsinsights_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/interviews/emp_interviewsinsights',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchDemographyinsights_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/companies/emp_company_demographyinsights',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchAtsinsights_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/jobs/atsinsights',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchUniversities_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/settings/universities',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };

    return {
        FetchAtsinsights_API,
        FetchDemographyinsights_API,
        FetchInterviewsinsights_API,
        FetchReviewsinsights_API,
        notifyapplicantMutation_API,
        assigncontribcompanyview_API,
        updateshowinHomesettingsMutation_API,
        fetchsuggestedcompanies_API,
        CompaniesSearch_API,
        FetchCompanyReviews_API,
        FetchCompanyInterviews_API,
        FetchSalaries_API,
        FetchBenefits_API,
        FetchUsers_API,
        Login_API,
        Checkauth_API,
        FetchPhotos_API,
        SalariesStatusMutation_API,
        BenefitsStatusMutation_API,
        FetchCompanyInfo_API,
        ReviewsStatusMutation_API,
        InterviewsStatusMutation_API,
        PhotosStatusMutation_API,
        CollectionMutation_API,
        FetchRewardsCollection_API,
        RewardsMutation_API,
        FetchRewards_API,
        CollectionDelete_API,
        RewardsDeleteMutation_API,
        CompanyMutation_API,
        CompanyDeleteMutation_API,
        AdduserMutation_API,
        UpdateuserMutation_API,
        DeleteuserMutation_API,
        DeleteReview_API,
        DeleteBenefit_API,
        DeleteInterview_API,
        DeleteOfficephotos_API,
        DeleteSalary_API,
        FetchCVRequestss_API,
        UpdatecompanylogoMutation_API,
        FetchIndustries_API,
        IndustriesMutation_API,
        IndustriesDelete_API,
        FetchCompanySizes_API,
        CompanySizesMutation_API,
        CompanySizesDelete_API,
        FetchSkills_API,
        SkillsMutation_API,
        SkillsDelete_API,
        FetchLocations_API,
        LocationsMutation_API,
        LocationsDelete_API,
        FetchJobs_API,
        JobsMutation_API,
        JobsDelete_API,
        FetchDepartments_API,
        DepartmentsMutation_API,
        DepartmentsDelete_API,
        FetchPaidfairly_API,
        PaidfairlyMutation_API,
        PaidfairlyDelete_API,
        ContribuionSettingsMutation_API,
        FetchContribuionSettings_API,
        FetchPaidfairlycontributions_API,
        FetchInterns_API,
        AddInternMutation_API,
        DeleteInternMutation_API,
        UpdateInternMutation_API,
        FetchCVs_API,
        ReviewsPinMutation_API,
        ReviewsFlagMutation_API,
        ReviewsEmployeeCommentMutation_API,
        FetchReviewComment_API,
        DeleteReviewComment_API,
        fetchAvaliableJobs_API,
        AddAvaliableJobMutation_API,
        fetchJobPhases_API,
        AddJobPhaseMutation_API,
        FetchQuestions_API,
        FetchAnswer_API,
        EmpcompanypdatemaininfoMutation_API,
        BycompanybenefitsMutation_API,
        FetchEmpcompanyleadership_API,
        CompanyupdateofficesMutation_API,
        DeleteCompanyupdateofficesMutation_API,
        CompanyupdatevideolinkMutation_API,
        CompanyupdatesocialmedialinksMutation_API,
        EmpcompanynamechangerequestMutation_API,
        FetchEmployerfaq_API,
        FetchCompaniesfollowers_API,
        FetchEmployerteam_API,
        EmployerteamMutation_API,
        EmployerteamEditMutation_API,
        EmployerteamDeleteMutation_API,
        FetchEmpcompanymissingfields_API,
        EmpqaanswerMutation_API,
        DeleteEmpqaanswerMutation_API,
        EmpcompanyinvitecompanyemployeeMutation_API,
        DeleteJobPhaseMutation_API,
        UpdateJobPhaseMutation_API,
        UpdateAvaliableJobMutation_API,
        DeleteAvaliableJobMutation_API,
        FetchAtsgenview_API,
        FetchAtsphaseview_API,
        DeleteEmpcompanyleadershipMutation_API,
        ChangePhaseMutation_API,
        ApplicantscommentsMutation_API,
        FetchApplicantscomments_API,
        QuestionaireMutation_API,
        FetchQuestionaire_API,
        DeleteQuestionMutation_API,
        DeleteApplicantMutation_API,
        FetchEmployerjobrejectreason_API,
        EmpcompanyextrafieldsMutation_API,
        EmpcompanybycompanyawardsMutation_API,
        DeleteAnswer_API,
        AnswerFlagMutation_API,
        QuestionFlagMutation_API,
        FetchInsights_API,
        FetchHomeInsights_API,
        FetchCompanyBadges_API,
        UnlockapplicantMutation_API,
        Fetchapplicant_API,
        FetchBadges_API,
        DeleteIntervieweviewComment_API,
        AddInterviewLike_API,
        AddReviewLike_API,
        FetchIntervieweviewComment_API,
        AddIntervieweviewComment_API,
        ReviewsgroupedbyjobsView_API,
        DuplicateJob_API,
        changePasswordMutation_API,
        FetchFaculties_API,
        FetchUniversities_API,
        deleteOfficephotosMutation_API,
    };
};
export default API;
