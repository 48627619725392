import '@fortawesome/fontawesome-free/css/all.min.css';
import React, { useContext } from 'react';

import { useHistory } from 'react-router-dom';
import API from '../../../API/API.js';
import { LanguageContext } from '../../../LanguageContext.js';
import '../Generalfiles/CSS_GENERAL/dropdown.css';
import { useQuery } from 'react-query';

const CompletionBar = (props) => {
    let history = useHistory();
    const { lang, setlang, langdetect } = useContext(LanguageContext);

    const { FetchEmpcompanymissingfields_API } = API();

    const FetchEmpcompanymissingfields = useQuery(['FetchEmpcompanymissingfields_API'], () => FetchEmpcompanymissingfields_API(), {
        keepPreviousData: true,
        staleTime: Infinity,
    });
    return (
        <>
            <div class="col-lg-12 p-0 ">
                <div class="su-progress">
                    <div class="su-progress-label">{FetchEmpcompanymissingfields?.data?.data?.percentagedone.toFixed(1)}% Profile completed</div>
                    <div class="su-progress-bar">
                        <div class="completed" style={{ width: FetchEmpcompanymissingfields?.data?.data?.percentagedone + '%' }}></div>
                    </div>
                </div>
            </div>
            {props.showbutton == true && (
                <div
                    onClick={() => {
                        history.push('/companyinfo');
                    }}
                    class="col-gl-12 p-0 text-primary text-secondaryhover"
                >
                    complete profile
                </div>
            )}
        </>
    );
};

export default CompletionBar;
