import React, { useContext, useEffect, useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import { useHistory } from 'react-router-dom';
import { Contexthandlerscontext } from '../../../Contexthandlerscontext.js';
import { LanguageContext } from '../../../LanguageContext';
import generalstyles from '../Generalfiles/CSS_GENERAL/general.module.css';
import { AiFillStar, AiOutlineArrowDown, AiOutlineArrowRight, AiOutlineArrowUp } from 'react-icons/ai';
import API from '../../../API/API.js';
import { useQuery } from 'react-query';
import { GrShare } from 'react-icons/gr';
import CompletionBar from '../CompletionBar/CompletionBar.js';
import ReviewsInsights from '../Insights/ReviewsInsights.js';
import LocationInsights from '../Insights/LocationInsights.js';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress/index.js';
import { BsGraphDownArrow, BsGraphUpArrow } from 'react-icons/bs';
import Rating from './Rating.js';
// Assets

const Home = () => {
    let history = useHistory();
    const { lang, langdetect } = useContext(LanguageContext);
    const { setpageactive_context, setpagetitle_context, fetchCompanyinfoContext, setScroll, fetchAuthorizationQueryContext } = useContext(Contexthandlerscontext);
    const { FetchEmpcompanymissingfields_API, FetchHomeInsights_API } = API();
    const [statistics, setstatistics] = useState([
        { name: 'Overall Rating', number: '' },
        { name: 'Reviews', number: '' },
        { name: 'Interview Reviews', number: '' },
        { name: 'Total Followers', number: '', path: './followers' },
        { name: 'New Followers', number: '', path: './followers' },
        { name: 'Company page views', number: '' },
        { name: 'Open Jobs', number: '' },
    ]);
    const [companyinfo, setcompanyinfo] = useState({});
    const [links, setlinks] = useState([
        { name: 'Create your brand', subinks: [] },
        { name: 'Add more about your brand', subinks: [] },
        {
            name: 'Add Social media links',
            subinks: [],
        },
        { name: 'Engage with your employees', subinks: [{ name: 'Respond to the reviews', path: '/reviews' }] },
    ]);

    const [ratings, setratings] = useState([
        // { name: lang.overallrating, payloadname: 'rate_overallrating' },
        { name: lang.salaryandbenefits, payloadname: 'rate_salaryandbenefits__avg' },
        { name: lang.companycultureemployeeengagement, payloadname: 'rate_companyculture__avg' },
        { name: lang.personaldevelopment, payloadname: 'rate_skilldevelopandlearning__avg' },
        { name: lang.careergrowth, payloadname: 'rate_promotionsandappraisal__avg' },
        { name: lang.managementandleadershipcapabilities, payloadname: 'rate_managementandleadershipcapabilities__avg' },
        { name: lang.worklifebalanceandoverallwellbeing, payloadname: 'rate_worklifebalance__avg' },
        { name: lang.diversityandinclusion, payloadname: 'rate_worklifebalance__avg' },
    ]);
    const FetchEmpcompanymissingfields = useQuery(['FetchEmpcompanymissingfields_API'], () => FetchEmpcompanymissingfields_API(), {
        keepPreviousData: true,
        staleTime: Infinity,
    });
    const FetchHomeInsights = useQuery(['FetchHomeInsights_API'], () => FetchHomeInsights_API(), {
        keepPreviousData: true,
        staleTime: Infinity,
    });

    const Calculatepercentage = (lastmonth, currentmonth) => {
        if (lastmonth != 0) {
            return (((parseFloat(currentmonth) - parseFloat(lastmonth)) / parseFloat(lastmonth)) * 100)?.toFixed(0) + '%';
        } else {
            return '';
        }
    };
    useEffect(() => {
        if (FetchHomeInsights?.isSuccess && !FetchHomeInsights?.isFetching) {
            var temp1 = FetchHomeInsights?.data?.data;
        }
        if (fetchCompanyinfoContext.isSuccess && !fetchCompanyinfoContext.isFetching) {
            var temp = fetchCompanyinfoContext?.data?.data?.data;

            setcompanyinfo({ ...temp });
            setstatistics([
                { name: 'Overall Rating', number: temp?.overallratings + '/5', description: 'Your current ratings.' },
                {
                    name: 'Total Reviews',
                    number: temp1?.reviews_monthly?.totalreviews,
                    number1: temp1?.reviews_monthly?.current_periodreviews,
                    lastmonth: temp1?.reviews_monthly?.current_periodreviews_submonth,
                    description: 'Number of reviews on your company.',
                },
                {
                    name: 'Interview Reviews',
                    number: temp1?.interviews_monthly?.totalintereviews,
                    number1: temp1?.interviews_monthly?.current_periodinterreviews,
                    lastmonth: temp1?.interviews_monthly?.current_periodinterreviews_submonth,
                    description: 'Number of reviews on your company.',
                },
                { name: 'Total Followers', number: temp1?.totalnewfollowerscurrentmonth?.totalfollowers, path: './followers', description: 'Total number of people following your company.' },
                {
                    name: 'New Followers',
                    number: temp1?.totalnewfollowerscurrentmonth?.current_periodfollowers,
                    number1: temp1?.totalnewfollowerscurrentmonth?.current_periodfollowers,
                    lastmonth: temp1?.totalnewfollowerscurrentmonth?.current_periodfollowers_submonth,
                    description: 'Number of new followers you have gained this month.',
                },
                {
                    name: 'Company page views',
                    number: temp1?.viewsgroupedbymonthdata?.totalviewssum,
                    number1: temp1?.viewsgroupedbymonthdata?.current_periodtotalviews,
                    lastmonth: temp1?.viewsgroupedbymonthdata?.current_periodovertotalviews_submonth,
                    description: 'Number of times people viewed your company page',
                },
                { name: 'Open Jobs', number: temp1?.totalopenjobs, description: 'Number of open jobs you have currently' },
            ]);
        }
    }, [fetchCompanyinfoContext.isSuccess, fetchCompanyinfoContext.data, FetchHomeInsights?.isSuccess, FetchHomeInsights?.data]);

    useEffect(() => {
        if (FetchEmpcompanymissingfields.isSuccess && !FetchEmpcompanymissingfields.isFetching) {
            var temp = FetchEmpcompanymissingfields?.data?.data?.data;
            var temp1 = [...links];
            temp?.map((item, index) => {
                if (item.missing == 1) {
                    if (item.name == 'companylogo') {
                        temp1[0].subinks.push({ name: 'Update your compnay logo', path: '/companyinfo' });
                    } else if (item.name == 'companysubmittedbenefits') {
                        temp1[0].subinks.push({ name: 'Update Employee Benefits offered', path: '/companyinfo/benefits' });
                    } else if (item.name == 'companyleadership') {
                        temp1[1].subinks.push({ name: 'Update Leadership', path: '/companyinfo/leadership' });
                    } else if (item.name == 'companylocations') {
                        temp1[1].subinks.push({ name: 'Update Locations', path: '/companyinfo/offices' });
                    } else if (item.name == 'twitter') {
                        temp1[2].subinks.push({ name: 'Update Twitter link', path: '/companyinfo/socialpresence' });
                    } else if (item.name == 'youtube') {
                        temp1[2].subinks.push({ name: 'Update Youtube link', path: '/companyinfo/socialpresence' });
                    } else if (item.name == 'facebook') {
                        temp1[2].subinks.push({ name: 'Update Facebook link', path: '/companyinfo/socialpresence' });
                    } else if (item.name == 'instagram') {
                        temp1[2].subinks.push({ name: 'Update Instageam link', path: '/companyinfo/socialpresence' });
                    } else if (item.name == 'linkedin') {
                        temp1[2].subinks.push({ name: 'Update Linkdin link', path: '/companyinfo/socialpresence' });
                    }
                }
            });
            setlinks([...temp1]);
        }
    }, [FetchEmpcompanymissingfields.isSuccess, FetchEmpcompanymissingfields.data]);

    useEffect(() => {
        setpagetitle_context('dashboard');
        setpageactive_context('/home');

        // window.scrollTo(0, 0);
    }, []);

    return (
        <div class="row m-0 w-100 p-md-2 pt-4 pl-3 pr-3">
            {(FetchEmpcompanymissingfields?.isFetching || fetchCompanyinfoContext?.isFetching || FetchHomeInsights?.isFetching) && (
                <div style={{ minHeight: '40vh' }} class="row m-0 w-100 allcentered mt-sm-2 pb-5 pb-md-0">
                    <CircularProgress color="var(--primary)" width="70px" height="70px" duration="1s" />
                </div>
            )}
            {!FetchEmpcompanymissingfields?.isFetching &&
                !fetchCompanyinfoContext?.isFetching &&
                !FetchHomeInsights?.isFetching &&
                FetchEmpcompanymissingfields?.isSuccess &&
                fetchCompanyinfoContext?.isSuccess &&
                FetchHomeInsights?.isSuccess && (
                    <div class="row m-0 w-100 d-1 justify-content-start mt-sm-2 pb-5 pb-md-0">
                        <div class="col-lg-12 p-0">
                            <div class="row m-0 d-flex align-items-center w-100">
                                <span style={{ fontSize: '30px' }} class="Carbona-Black mr-2">
                                    {companyinfo?.companyname}
                                </span>
                                <span class="d-flex align-items-center">
                                    {[...Array(5)].map((element, index) => {
                                        if (index + 1 <= parseInt(fetchCompanyinfoContext?.data?.data?.data?.overallratings)) {
                                            return <AiFillStar size={23} color={'#ffab00'} />;
                                        } else {
                                            return <AiFillStar size={23} color={'gray'} />;
                                        }
                                    })}
                                </span>
                                {/* <ReactStars count={5} edit={false} value={fetchCompanyinfoContext?.data?.data?.data?.overallratings} size={25} activeColor="#ffab00" /> */}

                                <GrShare
                                    class="text-primaryhover mr-1 ml-1"
                                    onClick={() => {
                                        window.open('https://www.corridortalks.com/companyinfo/' + companyinfo?.id + '?page=about', '_blank');
                                    }}
                                />
                            </div>
                            <div class="col-lg-12 p-0">
                                <span style={{ fontSize: '14px', color: 'gray' }}>you can manage your company page, respond to reviews and update your company information</span>
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-12 mt-4">
                            <div class="row m-0 w-100 allcentered">
                                {statistics?.map((item, index) => {
                                    return (
                                        <div class="col-lg-3 col-md-3 col-sm-12 mb-3">
                                            <div
                                                style={{ cursor: item?.path?.length != 0 ? 'cursor' : 'default', minHeight: '140px' }}
                                                class={generalstyles.shadowcardnohover + ' row m-0 d-flex align-items-center w-100 '}
                                            >
                                                <div class="col-lg-11 mt-2 ">{item.name}</div>
                                                <div class="col-lg-1">
                                                    <span class={generalstyles.tooltip}>
                                                        i<span class={generalstyles.tooltipwrapper}>{item?.description}</span>
                                                    </span>
                                                </div>
                                                <div
                                                    class={
                                                        (item?.path?.length != 0 && item?.path != undefined) || (item?.lastmonth?.length != 0 && item?.lastmonth != undefined)
                                                            ? 'col-lg-12 mt-2 mb-1 '
                                                            : 'col-lg-12 mt-3 mb-3 '
                                                    }
                                                >
                                                    <span style={{ fontSize: '30px' }} class="Carbona-Black mr-2 ">
                                                        {item.number}
                                                    </span>
                                                </div>
                                                {item?.lastmonth?.length != 0 && item?.lastmonth != undefined && (
                                                    <div
                                                        style={{
                                                            color: parseFloat(item?.lastmonth) > parseFloat(item?.number1) ? 'var(--danger)' : 'var(--success)',
                                                            fontSize: '12.5px',
                                                        }}
                                                        class="col-lg-12 d-flex align-items-center "
                                                    >
                                                        {item?.number1} {Calculatepercentage(item?.lastmonth, item?.number1)}{' '}
                                                        {parseFloat(item?.lastmonth) < parseFloat(item?.number1) && <BsGraphUpArrow class="mr-2 ml-1" />}
                                                        {parseFloat(item?.lastmonth) > parseFloat(item?.number1) && <BsGraphDownArrow class="mr-2 ml-1" />}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                                {/* <div class="col-lg-12 p-0">
                    <div class={'container p-4'}>
                        <div class="bar-chart-container row w-100">
                            <div class="bar-graph-heading ">Reviews</div>
                            <div class=" row w-100">
                                <div class="col-lg-6">
                                    <div class="col-lg-12 p-0">Top roles of new reviews</div>
                                    <ReviewsInsights />
                                </div>
                                <div class="col-lg-6">
                                    <div class="col-lg-12 p-0">Top job locations of new reviews</div>

                                    <LocationInsights />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12 mt-4">
                            <div class={generalstyles.shadowcardnohover + ' row m-0 w-100 '}>
                                <div class="col-lg-12 p-0 mb-4">
                                    <div class="row m-0 d-flex align-items-center w-100 mb-3">
                                        <span style={{ fontSize: '16px' }} class="Carbona-Black mr-2">
                                            Build your Company Profile
                                        </span>
                                    </div>
                                    <CompletionBar />
                                </div>
                                {links?.map((item, index) => {
                                    if (item.subinks.length != 0) {
                                        return (
                                            <div class="col-lg-12 p-0 mb-3">
                                                <div class="row m-0 w-100">
                                                    <div class="col-lg-12 p-0 mb-2 Carbona-Black" style={{ fontSize: '14px' }}>
                                                        {item.name}
                                                    </div>
                                                    {item?.subinks?.map((subitem, subindex) => {
                                                        return (
                                                            <div
                                                                onClick={() => {
                                                                    history.push(subitem.path);
                                                                }}
                                                                class="col-lg-12 p-0 text-primary text-secondaryhover"
                                                                style={{ fontSize: '12px' }}
                                                            >
                                                                {subitem.name}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>

                        <div className={generalstyles.shadowcardnohover + ' col-lg-12  mt-3  p-4 '}>
                            <div class="col-lg-12 ">
                                <div class="row m-0 d-flex align-items-center justify-content-between w-100">
                                    <span style={{ fontSize: '25px' }} class="Carbona-Black mr-2">
                                        Ratings
                                    </span>
                                    <span class={generalstyles.tooltip}>
                                        i<span class={generalstyles.tooltipwrapper}>Your rating breakdown</span>
                                    </span>
                                </div>
                                <div class="col-lg-12 p-0 mb-4">
                                    <span style={{ fontSize: '12px', color: 'gray' }}>Reviews are provided by current and formal employees</span>
                                </div>
                            </div>
                            <div class="col-lg-12 table_responsive scrollmenuclasssubscrollbar p-0">
                                <Rating ratings={ratings} data={FetchHomeInsights?.data?.data?.reviewAvgCat} />
                                {/* <table className={'table'}>
                                    <thead className=" mb-3">
                                        <th>{'Location'}</th>
                                        {ratings.map((subitem, subindex) => {
                                            return <th style={{ minWidth: '200px' }}>{subitem.name}</th>;
                                        })}
                                    </thead>
                                    <tbody>
                                        {FetchHomeInsights?.data?.data?.reviewsgroupedbylocations?.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td style={{ minWidth: '100px' }} class="col-lg-1">
                                                        <p className={' m-0 p-0 wordbreak text-capitalize '}>{item.locationname}</p>
                                                    </td>
                                                    {ratings.map((subitem, subindex) => {
                                                        return (
                                                            <td>
                                                                <p className={' m-0 p-0 wordbreak '}>
                                                                    {item[subitem.payloadname].toFixed(2)}

                                                                    <AiFillStar color={'yellow'} />
                                                                </p>
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table> */}
                            </div>
                        </div>
                    </div>
                )}
        </div>
    );
};
export default Home;
