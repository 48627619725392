import React, { useState, useEffect } from 'react';
import { FiUser, FiUsers } from 'react-icons/fi';
import { FaBuilding, FaIndustry, FaRegEnvelope, FaStethoscope, FaUsers, FaUsersCog } from 'react-icons/fa';

import { MdOutlineReviews } from 'react-icons/md';

import { GrBusinessService, GrChatOption } from 'react-icons/gr';
import { FaMoneyBill } from 'react-icons/fa';
import { RiBubbleChartFill, RiGalleryLine, RiHandHeartLine, RiUserFollowFill } from 'react-icons/ri';
import { BsCollection, BsEnvelopePaper, BsFillBriefcaseFill, BsFillBuildingsFill, BsPieChartFill, BsQuestionSquare, BsTrophyFill } from 'react-icons/bs';
import { SlBadge } from 'react-icons/sl';
import { TbBadgeFilled, TbMessages } from 'react-icons/tb';

import { useQuery, useMutation } from 'react-query';
import API from './API/API';

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';

import Cookies from 'universal-cookie';
import { NotificationManager } from 'react-notifications';
import { IoLocationSharp } from 'react-icons/io5';
import { BiCoinStack } from 'react-icons/bi';
import { AiFillFile, AiFillPieChart, AiOutlineFile, AiOutlineQuestionCircle } from 'react-icons/ai';
import { IoMdGitCompare, IoMdHome } from 'react-icons/io';
export const Contexthandlerscontext = React.createContext();
export const Contexthandlerscontext_provider = (props) => {
    const { fetchuseauthorization, Checkauth_API, Login_API, FetchCompanyInfo_API } = API();
    const [scroll, setScroll] = useState(false);
    const cookies = new Cookies();
    let history = useHistory();

    const [openloginmodalcontext, setopenloginmodalcontext] = useState(true);
    const [allcachedproductscontext, setallcachedproductscontext] = useState([]);
    const fetchAuthorizationQueryContext = useQuery(['Checkauth_API'], () => Checkauth_API(), {
        keepPreviousData: true,
        staleTime: Infinity,
    });
    const fetchCompanyinfoContext = useQuery(
        ['FetchCompanyInfo_API' + fetchAuthorizationQueryContext?.data?.data?.userinfo?.user_profile?.emp_company?.id],
        () => FetchCompanyInfo_API({ company_id: fetchAuthorizationQueryContext?.data?.data?.userinfo?.user_profile?.emp_company?.id }),
        {
            keepPreviousData: true,
            staleTime: Infinity,
            enabled: fetchAuthorizationQueryContext?.data?.data?.userinfo?.user_profile?.emp_company?.id != undefined ? true : false,
        },
    );

    const LoginmutationContext = useMutation('Login_API', {
        mutationFn: Login_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.warning('', 'Error');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                // NotificationManager.success('Login Success', 'Success');
                cookies.set('coas12312efaasasdafasdas32131asdsadsadsaqweasdkjenfjenfk kern123!@_#!@3123', data.data.access);
                fetchAuthorizationQueryContext.refetch();
            } else {
                NotificationManager.warning(data.data.reason, 'Warning');
            }
        },
    });
    const [pagesarray_context, setpagesarray_context] = useState([
        // {
        //     name: 'Admins',
        //     isselected: false,
        //     icon: (
        //         <i class={'allcentered'}>
        //             <FiUsers size={20} />
        //         </i>
        //     ),
        //     path: '/admins',
        // },
        {
            name: 'Home',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <IoMdHome size={17} />
                </i>
            ),
            path: '/home',
        },
        {
            name: 'Company Info',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <BsFillBuildingsFill size={17} />
                </i>
            ),
            subitems: [
                { name: 'overview', path: '/companyinfo/overview' },
                { name: 'employee benefits', path: '/companyinfo/benefits' },
                { name: 'leadership', path: '/companyinfo/leadership' },
                { name: 'offices', path: '/companyinfo/offices' },
                { name: 'cover photo', path: '/companyinfo/coverphoto' },
                { name: 'office photos', path: '/companyinfo/officephotos' },
                { name: 'videos', path: '/companyinfo/videos' },
                { name: 'social presence', path: '/companyinfo/socialpresence' },
                { name: 'Awards & Recognition', path: '/companyinfo/awards' },
                { name: 'Other Details', path: '/companyinfo/extras' },
            ],
            path: '/companyinfo',
        },
        {
            name: 'Reviews',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <MdOutlineReviews size={17} />
                </i>
            ),
            path: '/reviews',
        },
        {
            name: 'Analytics',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <AiFillPieChart size={17} />
                </i>
            ),
            subitems: [
                { name: 'Insights', path: '/insights' },
                { name: 'Review Insights', path: '/reviewsanalytics' },
                { name: 'Interview Insights', path: '/interviewsanalytics' },
                { name: 'Competitors Analytics', path: '/competitorsanalytics' },
                { name: 'ATS Analytics', path: '/atsanalytics' },
                { name: 'Demography Analytics', path: '/demographyanalytics' },
            ],
            path: '/insights',
        },

        {
            name: 'Badges',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <TbBadgeFilled size={17} />
                </i>
            ),
            path: '/badges/rating',
        },
        {
            name: 'Q&A',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <BsQuestionSquare size={17} />
                </i>
            ),
            path: '/qanda',
        },
        {
            name: 'Jobs & ATS',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <AiOutlineFile size={17} />
                </i>
            ),
            path: '/jobs',
        },
        {
            name: 'Team',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <FaUsers size={17} />
                </i>
            ),
            path: '/teammembers',
        },
        // {
        //     name: 'Followers',
        //     isselected: false,
        //     icon: (
        //         <i class={'allcentered'}>
        //             <RiUserFollowFill size={17} />
        //         </i>
        //     ),
        //     path: '/followers',
        // },
        {
            name: 'Invite Employees',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <BsEnvelopePaper size={17} />
                </i>
            ),
            path: '/inviteemployees',
        },
        {
            name: 'FAQs',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <AiOutlineQuestionCircle size={17} />
                </i>
            ),
            path: '/faqs',
        },
        {
            name: 'Contact Us',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <FaRegEnvelope size={17} />
                </i>
            ),
            path: '/contactus',
        },
    ]);

    const [pagetitle_context, setpagetitle_context] = useState('');
    const [pagetypecontext, setpagetypecontext] = useState('');

    const [hidesidenav_context, sethidesidenav_context] = useState(false);

    const setpageactive_context = (route) => {
        var temparr = [...pagesarray_context];
        temparr.forEach((sideelement, index) => {
            if (sideelement.path == route) {
                sideelement['isselected'] = true;
            } else {
                sideelement['isselected'] = false;
            }
        });

        setpagesarray_context([...temparr]);
    };
    const dateformatter = (date) => {
        // var string = date;

        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', hour12: true };
        return new Date(date).toLocaleDateString(undefined, options);
    };

    const dateformatter2 = (date) => {
        // var string = date;

        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
    };

    const dateformatter3 = (date) => {
        // var string = date;

        const options = { year: 'numeric', month: 'short' };
        return new Date(date).toLocaleDateString(undefined, options);
    };
    return (
        <Contexthandlerscontext.Provider
            value={{
                pagesarray_context,
                setpagesarray_context,
                pagetitle_context,
                setpagetitle_context,
                setpageactive_context,
                hidesidenav_context,
                sethidesidenav_context,
                openloginmodalcontext,
                setopenloginmodalcontext,
                fetchAuthorizationQueryContext,
                allcachedproductscontext,
                setallcachedproductscontext,
                scroll,
                setScroll,
                dateformatter,
                LoginmutationContext,
                fetchCompanyinfoContext,
                dateformatter2,
                dateformatter3,
                pagetypecontext,
                setpagetypecontext,
            }}
        >
            {props.children}
        </Contexthandlerscontext.Provider>
    );
};
