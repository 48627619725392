import React, { Component } from 'react';
import Cookies from 'universal-cookie';
export const LanguageContext = React.createContext();
export class LanguageProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: {
                en: {
                    companies: 'Companies',
                    addcompany: 'Add Company',
                    companyname: 'Company Name',
                    editcompany: 'Edit Company',
                    uploadlogo: 'Upload Logo',
                    employeecount: 'Employee count',
                    private: 'Private',
                    public: 'Public',
                    ownership: 'Ownership',
                    foundin: 'Found in',
                    ceo: 'CEO',
                    fblink: 'Facebook link',
                    linkedinlink: 'Linkedin link',
                    twitterlink: 'Twitter link',
                    websitelink: 'Website link',
                    companyemail: 'Company email',
                    companynumber: 'Company number',
                    mission: 'Mission',
                    reviews: 'Reviews',
                    addreview: 'Add review',
                    jobtitle: 'Job Title',
                    status: 'Status',
                    rejected: 'Rejected',
                    accepted: 'Accepted',
                    pending: 'Pending',
                    delete: 'Delete',
                    nostatus: 'No status',
                    show: 'Show',
                    reviewid: 'Review ID',
                    currentlyworkthere: 'Currently work there',
                    startdate: 'Started working on',
                    enddate: 'Employment ended on',
                    likes: 'Likes',
                    dislikes: 'Dislikes',
                    doesjobneedtravel: 'Job require travelling',
                    workingdays: 'Working days',
                    workinghoursstrictorflexible: 'Shift Timing',
                    gender: 'Gender',
                    companypolicy: 'Work policy at company',
                    employementtype: 'Employment type',
                    department: 'Department',
                    submittedat: 'Submitted at',
                    interviews: 'Interviews',
                    roundtips: 'Round Tips',
                    roundquestions: 'Round Questions',
                    question: 'Question',
                    answer: 'Answer',
                    wherethereresumescreen: 'Was there a resume screening before the interview',
                    howdidyougetinterview: 'Interview Source',
                    wheninterview: 'When was the interview',
                    interviewprocessduration: 'Interview process duration',
                    interviewdifficulty: 'Interview difficulty',
                    gotoffer: 'Interview result',
                    users: 'Users',
                    username: 'User Name',
                    email: 'Email',
                    signedat: 'Signed at',
                    createdat: 'Created at',
                    salaries: 'Salaries',
                    fixedsalary: 'Fixed salary',
                    variablesalary: 'Variable salary',
                    whichyearsalary: 'Which year’s salary',
                    lastincrementpercentage: 'Last increment percentage',
                    yearofincrement: 'Year of increment',
                    totalworkingexperience: 'Total work experience',
                    officelocation: 'Office location',
                    contributionid: 'Contribution ID',
                    userid: 'User ID',
                    adduser: 'Add user',
                    firstname: 'First name',
                    lastname: 'Last name',
                    password: 'Password',
                    phonenumber: 'Phone Number',
                    edit: 'Edit',
                    collectionname: 'Collection name',
                    numberofrewards: 'Number of rewards',
                    collectionid: 'Collection ID',
                    addcollection: 'Add collection',
                    rewardscollections: 'Rewards collections',
                    rewards: 'Rewards',
                    addreward: 'Add reward',
                    editreward: 'Edit reward',
                    rewardid: 'Reward ID',

                    brandlogo: 'Brand logo',
                    brandname: 'Brand name',
                    brandmaininfo: 'Brand main info',
                    description: 'Description',
                    rewardmaininfo: 'Reward main info',
                    price: 'Price',
                    percentage: 'Percentage',
                    validtill: 'Valid till',
                    cost: 'Cost',
                    steps: 'Steps to redeem',
                    termsandconditions: 'Terms & Conditions',

                    submittedphotos: 'Submitted photos',

                    reason: 'Reason',
                    updatestatus: 'update status',
                    add: 'Add',
                    active: 'Active',
                    draft: 'Draft',
                    title: 'Title',
                    priceaftersale: 'Price after sale',
                    coinsrequired: 'Coins required to redeem',
                    hassale: 'Has sale',
                    collections: 'Collections',
                    company: 'Company',
                    socialmedialink: 'Socialmedia link',
                    founder: 'Founder',
                    ownership: 'Ownership',
                    companydescription: 'Company description',
                    companybio: 'Company bio',
                    contactinfo: 'Contact info',
                    contactpersonname: 'Contact person name',
                    contactpersonnumber: 'Contact person number',
                    streetname: 'Street name',
                    buildingnumber: 'Building number',
                    apartmentnumber: 'Apartment number',
                    bio: 'Bio',
                    birthdate: 'Birth date',
                    female: 'Female',
                    male: 'Male',
                    update: 'Update',
                    companymaininfo: 'Company main info',
                    companylogo: 'Company logo',
                    refcode: 'ref_code',
                    importusers: 'Import users',
                    import: 'import',
                    tableformat: 'Table format',
                    importcompanies: 'Import companies',
                    coupon: 'Coupon',

                    industries: 'Industries',
                    addindustry: 'Add industry',
                    industryname: 'Industry name',

                    yes: 'Yes',
                    no: 'No',
                    primary: 'Primary',
                    other: 'Other',
                    type: 'Type',
                    industryid: 'Industry ID',
                    size: 'Size',
                    addcompanysize: 'Add Company size',
                    companysizeid: 'Company size ID',
                    companysizes: 'Company sizes',
                    skills: 'Skills',
                    skillname: 'Skill name',
                    addskill: 'Add skill',
                    skillid: 'Skill ID',
                    addlocation: 'Add Location',
                    locations: 'Locations',
                    location: 'Location',
                    locationid: 'Location ID',
                    companysizetype: 'Company size type',
                    companyemployeesizetype: 'Company employee size type',

                    local: 'Local',
                    biglocal: 'Big Local',
                    startup: 'Startup',
                    multinational: 'Multinational',
                    departments: 'Departments',
                    adddepartment: 'Add department',
                    departmentid: 'Department ID',
                    department: 'Department',
                    jobtitle: 'Job title',
                    job: 'Job',
                    jobs: 'Jobs',
                    addjob: 'Add job',
                    jobid: 'Job ID',
                    benefits: 'Benefits',
                    senioritylevel: 'Seniority level',
                    avaragesalary: 'Avarage salary',
                    addsalary: 'Add avarage salary',
                    avaragesalaries: 'Avarage salaries',
                    id: 'ID',
                    visible: 'Visible',
                    hidden: 'Hidden',
                    contribustioncoins: 'Contribustion Coins',
                    save: 'Save',
                    showinhome: 'Show in home',
                    hide: 'Hide',
                    showinpopularsearch: 'Show in popular search',
                    import: 'import',
                    export: 'export',
                    experience: 'Experience',
                    salary: 'Salary',
                    importskills: 'Import Skills',

                    overallrating: 'Overall Rating',
                    salaryandbenefits: 'Salary and Benefits',
                    companycultureemployeeengagement: 'Company Culture & Employee Engagement',
                    personaldevelopment: 'Personal Development',
                    careergrowth: 'Career Growth',
                    managementandleadershipcapabilities: 'Management and Leadership Capabilities',
                    worklifebalanceandoverallwellbeing: 'Work-Life Balance and Overall Wellbeing',
                    diversityandinclusion: 'Diversity & Inclusion',

                    whatarethestrengths: 'What are the strenghts',
                    whatarethedevelopmentareas: 'What are the development areas',
                    whatrecommendationswouldyougivetothemanagement: 'What recommendations would you give to the management',
                    wouldyourecommendthiscompanytoafriend: 'Would you recommend this company to a friend',
                    areyouconsideringleaving: 'Are you considering leaving',
                    organisationallevel: 'Organisational Level',
                    employmenettype: 'Employmenet type',
                    areyouconsideringreapplyingagain: 'Are you considering re-applying again?',

                    overallinterviewexperience: 'Overall interview experience',
                    employerbrandrepresentation: 'Employer brand representation',
                    interviewersquestionsandstyle: 'Interviewers questions and style',
                    lengthoftherecruitmentprocess: 'Length of the recruitment process',
                    courtesyandprofessionalismduringrecruitmentprocess: 'Courtesy and professionalism during recruitment process',
                    interviewtitle: 'Interview title',
                    reviewtitle: 'Review title',
                    interns: 'Interns',
                    addintern: 'Add interns',

                    name: 'Name',
                    importinterns: 'Import Interns',
                    cvtitle: 'CV title',
                    allcvs: 'All CVs',

                    nousers: 'No users',
                    nointerns: 'No Interns',
                    nocvs: 'No CVs',
                    noindustries: 'No Industries',
                    nocompanis: 'No Companies',
                    noindustries: 'No Industries',
                    nocompanysizes: 'No Company sizes',
                    noskills: 'No Skills',
                    nolocations: 'No Locations',
                    nodepartments: 'No Departments',
                    nojobs: 'No Jobs',
                    nocontributions: 'No Contributions',
                    nocompanies: 'No Companies',
                    noreviews: 'No Reviews',
                    nointerviews: 'No Interviews',
                    nosalaries: 'No Salaries',
                    nophotos: 'No Photos Contributed',
                    nobenefits: 'No Benefits Contributed',
                    norewards: 'No Rewards',
                    norewardcollections: 'No Reward Collections',
                    country: 'Country',
                    careersummary: 'Career summary',
                    companynamearabic: 'Company Name (ar)',
                    companydescriptionarabic: 'Company Description (ar)',
                    website: 'Website',
                    companybanner: 'Company banner',
                    updatebanner: 'Update banner',
                    suggestedcompanies: 'Suggested Companies',
                    searchcompany: 'Search Company',
                    awards: 'Awards',
                    addaward: 'Add Award',
                    awardname: 'Award Name',
                    awardlogo: 'Award Logo',
                    awardrank: 'Award Rank',
                    assigncompanies: 'Assign Companies',
                    uploadpdf: 'Upload pdf',

                    about: 'About',
                    company: 'Company',
                    officephotos: 'Office Photos',
                    viewall: 'View all',
                    ratings: 'Ratings',
                    reviews: 'Reviews',
                    contributeandEarnRewards: 'Contribute & Earn Rewards',
                    faqs: 'FAQs',
                    suggest: 'Suggest',
                    foundedin: 'Founded in',
                    ownership: 'Ownership',
                    egyptemployeecount: 'Egypt Employee count',
                    officelocations: 'Office Locations',
                    ceo: 'CEO',
                    founder: 'Founder',
                    typeofcompany: 'Type of Company',
                    natureofbusiness: 'Nature of Business',
                    companyemailid: 'Company Email ID',
                    companycontactno: 'Company Contact no.',
                    socialmediapresence: 'Social Media Presence',
                    website: 'Website',
                    requesthrcvreview: 'Review My CV',
                    keepthisanonymous: 'Keep this anonymous',
                    cancel: 'cancel',
                    comment: 'comment',
                    loginfirst: 'Login First',
                    submit: 'Submit',
                    pleasetryagain: 'please try again',
                    somethingwentwrong: 'Something went wrong',
                    askyourquestion: 'Ask your question',
                    getanswersfrom: 'Get answers from',
                    employees: 'employees',
                    postanonymously: 'Post anonymously',
                    postquestion: 'Post question',
                    salaries: 'Salaries',
                    benefitreviews: 'Benefit Reviews',
                    writeareview: 'Write a Review',
                    addsalary: 'Add Salary',
                    associatedcompanies: 'Associated Companies',
                    shareinterview: 'Share Interview Experience',
                    unfollow: 'Unfollow',
                    follow: 'Follow',
                    following: 'Following',
                    interviews: 'Interviews',
                    followers: 'Followers',
                    companiesin: 'Companies in',
                    companyname: 'Company Name',
                    salaryrating: 'Salary rating',
                    yrs: 'yrs',
                    compare: 'Compare',
                    vs: 'vs',
                    contributesalary: 'Contribute Salary',
                    addofficephotos: 'Add Office Photos',
                    shareyouroponion: 'Share your opinion!',
                    earncoins: 'Earn coins.',
                    department: 'Department',
                    mission: 'Mission',
                    primaryindustry: 'Primary Industry',
                    primaryindustrydescription: 'Primary industry refers to the industry from which a company drives its maximum revenue.',
                    otherindustry: 'Other Industry',
                    otherindustrydescription: 'Other industries refer to additional industries in which a company operates other than its primary industry.',
                    fulltime: 'Full Time',
                    parttime: 'Part Time',
                    contractual: 'Contractual',
                    intern: 'Intern',
                    freelancer: 'Freelancer',
                    local: 'local',
                    biglocal: 'big local',
                    startup: 'startup',
                    multinational: 'multinational',
                    clear: 'clear',
                    select: 'Select',
                    usersjobseekers: 'Users/Jobseekers',
                    corridortalks: 'CorridorTalks',
                    support: 'Support',
                    companies: 'Companies',
                    salarycalculator: 'Salary Calculator',
                    rewards: 'Rewards',
                    campusplacements: 'Campus Placements',
                    practicetest: 'Practice Test',
                    comparecompanies: 'Compare Companies',
                    aboutus: 'About Us',
                    emailus: 'Email Us',
                    privacy: 'Privacy',
                    grievances: 'Grievances',
                    termsofuse: 'Terms of Use',
                    summonsnotices: 'Summons/Notices',
                    communityguidelines: 'Community Guidelines',
                    givefeedback: 'Give Feedback',
                    footerdescription: 'Helping over 850k job seekers every month in choosing their right fit company',
                    usersmonth: 'Users/Month',
                    childcarefacility: 'Child care facility',
                    officecabshuttle: 'Office cab/shuttle',
                    cafeteria: 'Cafeteria',
                    freemeal: 'Free meal',
                    recreationalactivities: 'Recreational activities',
                    joininggoodies: 'Joining goodies',
                    giftsonfestivals: 'Gifts on festivals',
                    officegym: 'Office gym',
                    wfhsetup: 'WFH setup',
                    joiningbonus: 'Joining bonus',
                    performancebonus: 'Performance bonus',
                    relocationbonus: 'Relocation bonus',
                    stockoptions: 'Stock options',
                    mobilebillreimbursements: 'Mobile bill reimbursements',
                    retirementbenefits: 'Retirement benefits',
                    carlease: 'Car lease',
                    lifeinsurance: 'Life insurance',
                    healthinsurance: 'Health insurance',
                    annualhealthcheckup: 'Annual health checkup',
                    mentalhealthwellbeing: 'Mental health wellbeing',
                    gymmembership: 'Gym membership',
                    internationalonsiteexposure: 'International/On-site exposure',
                    jobsoftskilltraining: 'Job/Soft skill training',
                    rewardsandrecognition: 'Rewards & recognition',
                    coursereimbursements: 'Course reimbursements',
                    professionaldegreeassistance: 'Professional degree assistance',
                    maternityleaves: 'Maternity leaves',
                    annualleaves: 'Annual leaves',
                    paternityleaves: 'Paternity leaves',
                    careerbreaksabbatical: 'Career break/Sabbatical',
                    rateoverallbenefits: 'Rate the overall perks and benefits at your company',
                    whatisworkpolicy: 'What is the work policy at your company?',
                    totalworkexperience: 'Total work experience',
                    whatbenefitsdoyoulike: 'Which benefits you like the most at your company?',
                    whatbenefitsshoudbeimproved: 'What benefits should be improved in your company?',
                    pleaseloginfirst: 'Please Login first',
                    submitanonymously: 'Submit Anonymously',
                    rateyourcompanyanonymously: 'Rate your company, anonymously!',
                    shareyourinterviewquestions: 'Share your interview questions & help people get their dream job!',
                    shareyoursalaryanonymously: 'Share your salary, anonymously!',
                    areyoupaidenough: 'Are you paid enough?',
                    yourvoicemattersmakeitheard: 'Your voice matters, make it heard.',
                    findoutwhereyoustandamongyourpeers: 'Find out where you stand among your peers.',
                    howdidyougetthisinterview: 'How did you get this interview?',
                    jobportal: 'Job Portal',
                    campusplacement: 'Campus Placement',
                    referral: 'Referral',
                    walkin: 'Walk-in',
                    companywebsite: 'Company Website',
                    recruitmentconsultant: 'Recruitment Consultant',
                    newspapaperad: 'Newspaper Ad',
                    jobfair: 'Job Fair',
                    approachedbycompany: 'Approached by Company',
                    whenwasthisinterview: 'When was this interview?',
                    lessthanamonth: 'Less than a month',
                    oneetosixmonthsago: '1-6 months ago',
                    sixtotwelvemonthsago: '6-12 months ago',
                    morethanayearago: 'More than a year ago',
                    howlongwastheinterviewprocess: 'How long was the interview process?',
                    lessthantwoweeks: 'Less than 2 weeks',
                    twotofour: '2-4 weeks',
                    fourtosixweeks: '4-6 weeks',
                    sixtoeightweeks: '6-8 weeks',
                    morethaneightweeks: 'More than 8 weeks',
                    howdifficultwastheinterview: 'How difficult was the interview?',
                    easy: 'Easy',
                    moderate: 'Moderate',
                    hard: 'Hard',
                    didyougetanoffer: 'Did you get an offer?',
                    yesijoined: 'Yes, I joined the company',
                    yesbutideclinedtheoffer: 'Yes, but I declined the offer',
                    noididntrecieveanoffer: "No, I didn't receive an offer",
                    didnthearbackfromcompany: "I didn't hear back from the company",
                    success: 'Success',
                    wastherearesumescreeningbeforetheinterview: 'Was there a resume screening before the interview?',
                    yes: 'Yes',
                    no: 'No',
                    whatwasround: 'What was round',
                    sharedetails: 'Share details',
                    question: 'Question',
                    answer: 'Answer',
                    shareadviceforjobseekers: 'Share advice for job seekers',
                    shareyourtips: 'Share your tips',
                    rateoverallinterviewexperience: 'Rate overall interview experience',
                    terrible: 'Terrible',
                    poor: 'poor',
                    average: 'Average',
                    good: 'Good',
                    excellent: 'Excellent',
                    keepanonymous: 'Keep Anonymous',
                    confirmdelete: 'Confirm Delete',
                    areyousureyouwanttodeleterounddetails: 'Are you sure you want to delete round details?',
                    salary: 'Salary',
                    senioritylevel: 'Seniority level',
                    yearsofexperience: 'Years of experience',
                    letsfindout: "Let's Find out",
                    edit: 'Edit',
                    at: 'at',
                    experience: 'Experience',
                    recalculate: 'Recalculate',
                    youarepaid: 'You are paid',
                    marketvalue: 'market value',
                    yourmarketvalue: 'Your net market value',
                    year: 'year',
                    yourcurrentsalary: 'Your net current salary',
                    uploadphotos: 'Upload Photos',
                    or: 'or',
                    draganddrop: 'Drag & drop files from desktop',
                    photoselected: ' photo selected',
                    whatisthisphotoabout: 'What is this photo about',
                    officelocation: 'Office location',
                    pleasecompletemissingfields: 'Please complete the missing fields',
                    next: 'Next',
                    submitphotos: 'Submit Photos',
                    tipsandguidelines: 'Tips and guidelines',
                    overallrating: 'Overall Rating',
                    worklifebalance: 'Work-Life Balance',
                    salaryandbenefits: 'Salary and Benefits',
                    promotionsappraisal: 'Promotions / Appraisal',
                    jobsecurity: 'Job Security',
                    skilldevelopmentlearning: 'Skill Development / Learning',
                    worksatisfaction: 'Work Satisfaction',
                    companyculture: 'Company Culture & Employee Engagement',
                    selectmonth: 'Month',
                    selectyear: 'Year',
                    yournamewillremainconfidential: 'Your name will remain 100% confidential',
                    doyoucurrentlyworkhere: 'Do you currently work here?',
                    startedworkingon: 'Started working on',
                    employmentendedon: 'Employment ended on',
                    ratethecompanyonthefollowingcriteria: 'Rate the company on the following criteria',
                    whatdoyoulikeaboutworkingatyourcompany: 'What do you like about working at your company?',
                    likes: 'Likes',
                    whatdoyoudislikeaboutworkingatyourcompany: 'What do you dislike about working at your company?',
                    dislikes: 'Dislikes',
                    doesyourjobrequireyoutotravelforwork: 'Does your job require you to travel for work?',
                    whatareyourworkingdays: 'What are your working days?',
                    mondaytofriday: 'Monday to Friday',
                    mondaytosaturday: 'Monday to Saturday',
                    alternatesaturdaysoff: 'Alternate Saturdays off',
                    rotationalshift: 'Rotational Shift',
                    alldays: 'All 7 days',
                    other: 'Other',
                    specifytheexactworkingdays: 'Specify the exact working days',
                    areyourworkinghoursstrictorflexible: 'Are your working hours strict or flexible?',
                    strict: 'Strict',
                    flexiable: 'Flexible',
                    whatareyourshifttimings: 'What are your shift timings?',
                    starttime: 'Start time',
                    endtime: 'End time',
                    gender: 'Gender',
                    male: 'Male',
                    female: 'Female',
                    permanentworkfromhome: 'Permanent work from home',
                    workingfromoffice: 'Working from office',
                    hybrid: 'Hybrid (working from office & home)',
                    unclearatthemoment: 'Unclear at the moment',
                    enterin: 'Enter in %',
                    yearofincrement: 'Year of increment',
                    employmenttype: 'Employment Type',
                    interviewroundsandprocess: 'Interview Rounds and Process',
                    interviewpreparationtips: 'Interview Preparation Tips',
                    interviewquestionsprocessandtips: 'Interview Questions, Process, and Tips',
                    popularjobprofiles: 'Popular Job Profiles',
                    designationjob: 'Job Title',
                    questionsandanswers: 'Questions and Answers',
                    seequestionsabout: 'See questions about',
                    more: 'More',
                    moreanswers: 'more answers',
                    view: 'View',
                    promotions: 'Promotions',
                    categoryratings: 'Category Ratings',
                    explorereviewsbydepartment: 'Explore Reviews by Department',
                    topmentions: 'Top Mentions',
                    inreviews: 'in Reviews',
                    similarcompanies: 'Similar Companies',
                    difficultylevel: 'Difficulty Level',
                    duration: 'Duration',
                    result: 'Result',
                    round: 'Round',
                    readmore: 'Read More',
                    workdetails: 'Work Details',
                    workpolicy: 'Work Policy',
                    workdays: 'Work Days',
                    workrelatedtravel: 'Work related travel',
                    load: 'Load',
                    off: 'OFF',
                    claimfor: 'Claim for',
                    coins: 'Coins',
                    expiringsoon: 'Expiring soon!',
                    salariesinegypt: 'Salaries in Egypt',
                    ratedby: 'Rated by',
                    employeesforsalaryandbenefits: ' Employees for salary and benefits',
                    topdepartmentsat: 'Top Departments at',
                    designation: 'Designation',
                    avarageannualsalary: 'Average Annual Salary',
                    low: 'Low',
                    highest: 'Highest',
                    interviewsquestions: 'Interviews Questions',
                    aboutcompany: 'About Company',
                    whichcompanyordesignation: 'Which company?',
                    search: 'Search',
                    jobsdesignations: 'Jobs/ designations',
                    searchby: 'Search by',
                    discoverbestplacestowork: 'Discover best places to work',
                    bringyourworkplacetolife: 'Bring your workplace to life',
                    addcompanybenefits: 'Add Company Benefits',
                    highlightyourcompanyperks: "Highlight your company's perks",
                    companyreviews: 'Company reviews',
                    readreviews: 'Read reviews for companies',
                    rateyourformercompany: 'Rate your former or current employer',
                    browsesalaries: 'Browse salaries',
                    discoversalaries: 'Discover salaries for companies',
                    areyoupaidfairly: 'Are you paid fairly',
                    checkyourmarketvalue: 'Check your market value',
                    shareyoursalary: 'Share your salary',
                    helpotherjobseekers: 'Help other jobseekers',
                    companyinterviews: 'Company interviews',
                    readinterviewscompanies: 'Read interviews for companies',
                    addasalary: 'Add a Salary',
                    shareaninterview: 'Share an Interview',
                    contributetohelpmillions: 'Contribute to help millions!',
                    contribute: 'Contribute',
                    login: 'Login',
                    profile: 'Profile',
                    manageprefrences: 'Manage preferences',
                    logout: 'Logout',
                    edityourprofiledetails: 'Edit your profile details',
                    areyouastudentorworkingprofessional: 'Are you a student or working professional?',
                    studentNeverworked: 'Student/Never worked',
                    iamastudenthaveneverworked: 'I am a student / I have never worked',
                    workingprofessional: 'Working Professional',
                    iamworkinghaveworkedbefore: 'I am working / I have worked before',
                    yourworkexperience: 'Your work experience',
                    save: 'Save',
                    coming: 'Coming',
                    soon: 'soon',
                    location: 'Location',
                    industry: 'Industry',
                    companysize: 'Company size(Employees)',
                    companytype: 'Company type',
                    listofcompaniesinegypt: 'List of companies in Egypt',
                    uniquecompaniesfound: 'unique companies found',
                    companyrating: 'company rating',
                    andabove: '& above',
                    joinegyptslargestcommunity: 'Join Egypt’s largest community',
                    email: 'Email',
                    password: 'Password',
                    firstname: 'First Name',
                    lastname: 'Last Name',
                    confirmpassword: 'Confirm Password',
                    passwordsdontmatch: "Passwords don't match",
                    signup: 'Sign-up',
                    workfromhome: 'Work From Home',
                    gymanasium: 'Gymnasium',
                    softskillstraining: 'Soft Skills Training',
                    freefood: 'Free Food',
                    freetransport: 'Free Transport',
                    childcare: 'Child care',
                    educationassistance: 'Education Assistance',
                    internationalrelocation: 'International Relocation',
                    jobtraining: 'Job Training',
                    teamoutings: 'Team Outings',
                    readcompanyreviewstoavoidcareermistakes: 'Read company reviews to make better career choices',
                    explorecompanyreviews: 'Explore Company Reviews',
                    exploresalariesofmorethancompanies: "Explore company salaries and check if you're paid fairly!",
                    exploresalaries: 'Explore Salaries',
                    areyoupaidfairly: 'Are you paid fairly',
                    companywiseinterviewquestions: 'Company-wise Interview Experiences',
                    shareinterviewquestions: 'Share Interview Questions',
                    egyptsnoplatform: "Egypt's No. 1 platform",
                    for: 'for',
                    salaryinsights: 'Employer Insights',
                    popularsearches: 'Popular Searches',
                    findcompaniesthatdeserveyou: 'Find companies that you deserve!',
                    prepareyournextinterviewwithcorridortalks: 'Prepare your next interview with CorridorTalks',
                    egyptsinterviewpreparationplatform: 'Egypts #1 interview experience sharing platform',
                    withKcompanies: 'with companies',
                    crazybuttrueinterviewquestionsfromjobprofiles: 'Go prepared with real interview experiences from the community!',
                    preparefrominterviewquestions: ' Prepare from interview questions',
                    shineatyourtechnicalinterviewswithlakhinterviewquestionssparklogo: 'Browse real interview experiences for different roles and various companies!',
                    browsecompaniesbylocation: 'Browse Companies by Location',
                    browsecompaniesbyindustries: ' Browse Companies by Industries',
                    browsebycompanysize: 'Browse by Company Size',
                    basedonnumberofemployees: 'based on number of employees',
                    popularcompanies: 'Popular Companies',
                    getrewardsforhelpingothers: ' Get rewards for helping others',
                    yourcoinsbalance: 'Your Coins Balance',
                    claimed: 'Claimed',
                    coinshistory: 'Coins History',
                    all: 'All',
                    earned: 'Earned',
                    spent: 'Spent',
                    contributedofficephotos: 'Contributed office Photos',
                    contributedinterview: 'Contributed interview',
                    contributedreview: 'Contributed review',
                    contributedbenefits: 'Contributed Benefits',
                    claimedreward: 'Claimed Reward',
                    howdocoinswork: 'How do Coins work?',
                    sharethegoodness: 'Share the happiness!',
                    price: 'Price',
                    validtill: 'Valid till',
                    stepstoredeem: 'Steps to Redeem',
                    tandc: 'T&C',
                    earntoredeem: 'Earn to Redeem',
                    yousave: 'You Save',
                    doyouwanttoproceedwiththetransaction: 'Do you want to proceed with the transaction?',
                    coinswillbedebitedfromyouraccount: 'coins will be debited from your account',
                    proceed: 'Proceed',
                    searchandcomparesalaries: 'Search and Compare Salaries',
                    comparesalariesbydesignationsandexperience: 'Compare salaries by designations and experience.',
                    salarycomparison: 'Salary Comparison',
                    comparesalariesbetweenhighestpayingcompanies: 'Compare salaries between highest paying companies',
                    getrelevantsalaryresultsbyselectingyourdepartment: ' Get relevant salary results by selecting your department',
                    salariesbycompany: 'Salaries by Company',
                    enternewpassword: 'Enter new password',
                    enterannualsalaryexpectation: 'Enter annual salary expectation',
                    preferredjobprofiles: 'Preferred job profiles',
                    addyourpreferrredjobprofilestogetrelevantjobsuggestions: 'Add your preferred job profiles to get relevant job suggestions',
                    preferredindustry: 'Preferred industry',
                    addyourpreferrredindustrieswhereyouwanttowork: 'Add your preferred industries where you want to work',
                    salaryexpectation: 'Salary Expectation',
                    addyourminimumsalaryexpectationtogetrelevantjobsuggestions: 'Add your minimum salary expectation to get relevant job suggestions',
                    yourskills: 'Your skills',
                    addyourskillstogetrelevantjobsuggestions: 'Add your skills to get relevant job suggestions',
                    preferredlocations: 'Preferred locations',
                    addyourpreferrredlocationstogetrelevantjobsuggestions: 'Add your preferred locations to get relevant job suggestions',
                    manageyourpreferences: 'Manage your preferences',
                    updateyourpreferencestogetapersonalisedexperienceoncorridortalks: 'Update your preferences to get a personalized experience on CorridorTalks.',
                    add: 'Add',
                    enteryourpreferredindustry: 'Enter your preferred industry',
                    enteryourskills: 'Enter your skills',
                    enteryourpreferredlocations: 'Enter your preferred locations',
                    enteryourpreferredjobprofiles: 'Enter your preferred job profiles',
                    wantanenhancedexperience: ' Want an enhanced experience?',
                    clickhere: 'click here',
                    companiesyoufollow: 'Companies you follow',
                    youhaventfollowedanycompanysofar: "you haven't followed any company so far",
                    userinformation: ' User Information',
                    submittedon: 'submitted on',
                    postedon: 'posted on',
                    byyou: 'by you',
                    editprofileinfo: 'Edit Profile Info',
                    rateyourcompany: 'Rate your company',
                    youhaventwrittenanycompanyreviewsofar: "You haven't written any company review so far",
                    interviewexperience: 'Interview Experience',
                    submitinterviewquestions: 'Submit interview questions',
                    youhaventwrittenanyinterviewreviewsofar: "You haven't written any interview review so far",
                    salariesshared: 'Salaries shared',
                    submitasalary: 'Submit a salary',
                    youhaventsubmittedanysalarysofar: "You haven't submitted any salary so far",
                    companyphotos: 'Company Photos',
                    youhaventsubmittedanycompanyphotosofar: "You haven't submitted any company photo so far",
                    addbenefits: 'Add Benefits',
                    companybenefits: 'Company Benefits',
                    youhaventsubmittedanycompanybenefitssofar: "You haven't submitted any company benefits so far",
                    contributionsgiven: 'CONTRIBUTIONS GIVEN',
                    review: 'Review',
                    inerview: 'Interview',
                    salary: 'Salary',
                    averagesalary: 'Average Salary',
                    buildyourcv: 'Build your CV',
                    contributeyourinterviewquestions: 'Contribute your interview questions',
                    shareyoucompanybenefits: 'Share your company benefits',
                    helpotherjobseekersbysharingyoursalary: 'Help others by sharing your salary',
                    reviewtitle: 'Review Title',
                    personaldevelopment: 'Personal Development',
                    managementandleadershipcapabilities: 'Management and Leadership Capabilities',
                    diversityandinclusion: 'Diversity & Inclusion',
                    careergrowth: 'Career Growth',
                    whatarethestrengths: 'What are the strengths',
                    whatarethedevelopmentareas: 'What are the development areas',
                    whatrecommendationswouldyougivetothemanagement: 'What recommendations would you give to the management',
                    wouldyourecommendthiscompanytoafriend: 'Would you recommend this company to a friend',
                    areyouconsideringleaving: 'Are you considering leaving',
                    organisationallevel: 'Organisational Level',
                    intern: 'Intern',
                    nonmanagement: 'Non-management',
                    management: 'Management',
                    seniormanagement: 'Senior management',
                    director: 'Director',
                    wasanofferprovided: 'Was an offer provided',
                    interviewtitle: 'Interview experience title',
                    ratetherecruitmentprocessinthebelowcategories: 'Rate the recruitment process in the below categories',
                    employerbrandrepresentation: 'Employer brand representation',
                    interviewersquestionsandstyle: 'Interviewers questions and style',
                    lengthoftherecruitmentprocess: 'Length of the recruitment process',
                    courtesyandprofessionalismduringrecruitmentprocess: 'Courtesy and professionalism during recruitment process',
                    areyouconsideringreapplyingagain: 'Are you considering re-applying again?',
                    employmentstatus: 'Employment Status',
                    companycultureemployeeengagement: 'Company Culture & Employee Engagement',
                    worklifebalanceandoverallwellbeing: 'Work-Life Balance and Overall Wellbeing',
                    pleaselogintocheckyourredeemedrewards: 'Please login to check your redeemed rewards.',
                    loginsignup: 'Login/Signup',
                    guest: 'Guest',
                    bonusscheme: 'Bonus scheme',
                    fixedsalary: 'Fixed Salary',
                    whichyearssalaryisit: 'Which year’s salary is it?',
                    lastincrement: 'Last salary increase',
                    aretherestockoptions: 'Are there stock options?',
                    howmanyshares: 'How many shares?',
                    totalyearsofexperience: 'Total years of experience',
                    yearsofexperienceinthissenioritylevel: 'Years of experience in this seniority level',
                    interviewrounds: 'Interview Rounds',
                    interviewexperience: 'Interview experience',
                    monthlysalary: 'Monthly Net Salary (EGP)',
                    notaddedyet: 'Not Added yet',
                    searchcompanyreviewsratings: 'Search Company Reviews & Ratings',
                    readwhatemployeesaresayingaboutcompanies: 'Read what employees are saying about companies.',
                    share: 'Share',
                    viewcomments: 'view comments',
                    hidecomments: 'hide comments',
                    benefits: 'Benefits',
                    qanda: 'Q & A',
                    photos: 'Photos',
                    personalinformation: 'Personal Information',
                    name: 'Name',
                    country: 'Country',
                    phonenumber: 'Phone number',
                    socialmedialink: 'Socialmedia link',
                    careersummary: 'Career summary',
                    education: 'Education',
                    schoolname: 'School name',
                    college: 'College',
                    major: 'Major',
                    gpa: 'GPA',
                    doyoucurrentlystudyhere: 'Do you currently study here?',
                    startedon: 'Started on',
                    endedon: 'Ended on',
                    workexperiencecareerachievements: 'Work experience & career achievements',
                    title: 'Title',
                    job: 'Job',
                    summaryofasksandachievements: 'Summary of tasks and achievements',
                    entityname: 'Entity name',
                    trainingtitle: 'Training title',
                    professionaltraining: 'Professional training',
                    training: 'Training',
                    communityservice: 'Community service',
                    summaryofdonetasks: 'Summary of done tasks',
                    date: 'Date',
                    internshipsandfellowships: 'Internships and fellowships',
                    internship: 'Internship',
                    position: 'Position',
                    tasksdone: 'Tasks done',
                    enterskill: 'Enter skill',
                    languages: 'Languages',
                    youhavenocvsyet: 'You have not claimed this reward yet - contribute and claim now to build your resume in an easy and professional way!',
                    buildyourcvnow: 'Build your CV now',
                    cvtitle: 'CV title',
                    buildanotercv: 'Build another CV',
                    delete: 'Delete',
                    show: 'Show',
                    month: 'Month',
                    strengths: 'Strengths',
                    developmentareas: 'Development areas',
                    monthlysalary: 'Monthly Net Salary (EGP)',
                    wedonthaveinfoaboutthiscompanyyet: "We don't have info about this company yet",
                    home: 'Home',
                    browsecompanyreviews: 'Browse company reviews',
                    browsecompanyinterviews: 'Browse company interviews',
                    discovercompanies: 'Discover companies',
                    languageproficiencylevel: 'Language proficiency level',
                    language: 'Language',
                    beginner: 'Beginner',
                    intermediate: 'Intermediate',
                    advanced: 'Advanced',
                    careergrowth: 'Career Growth',
                    native: 'Native',
                    download: 'Download',
                    tasks: 'Tasks',
                    task: 'Task',
                    extracurricularactivities: 'Extracurricular activities',
                    areyoureferredbyanyofourinterns: 'Do you have a referral code?',
                    refcode: 'Ref Code',
                    personaldevlopement: 'Personal Development',
                    infounavailable: 'Info unavailable',
                    addanswer: 'Add answer',
                    successcontribution: ' Thank you for your submission, your contribution is being reviewed and should be approved very shortly!',
                    recommendedtofriend: 'would recommend to friends',
                    optional: 'optional',
                    alldepartments: 'All departments',
                    followus: 'Follow us',
                    rejectedbenefits: 'Your benefit contribution has been rejected',
                    acceptedbenefits: 'Your benefit contribution has been accepted',
                    pendingbenefits: 'Your benefit contribution is pending',
                    rejectedsalary: 'Your salary contribution has been rejected',
                    acceptedsalary: 'Your salary contribution has been accepted',
                    pendingsalary: 'Your salary contribution is pending',
                    sharetheperksandbenefitsofyourcompany: 'Share the perks and benefits of your company',
                    youhavenotclaimedthisrewardandclaimnowtohaveyourresumereviewedbyprofessionals:
                        'You have not claimed this reward yet - contribute and claim now to have your resume reviewed by HR professionals!',
                    requesthrReviewforyourcv: 'Request HR Review For Your CV',
                    notifications: 'Notifications',
                    rejectedreview: 'Your review contribution has been rejected',
                    acceptedreview: 'Your review contribution has been accepted',
                    pendingreview: 'Your review contribution is pending',
                    rejectedinterview: 'Your interview contribution has been rejected',
                    acceptedinterview: 'Your interview contribution has been accepted',
                    pendinginterview: 'Your interview contribution is pending',

                    employeecount: 'Employee Count',
                    employeebenefits: 'Employee Benefits',
                    job: 'Job',
                    jobdescription: 'Job description',
                    expirydate: 'Expiry Date',
                    phasename: 'Phase name',
                    index: 'Index',
                    addphase: 'Add phase',
                    companylocations: 'Company Locations',
                    teammembers: 'Team Members',
                    addteammember: 'Add Team Member',
                    followers: 'Followers',
                    companyfounded: 'Company Founded',
                    companyemail: 'Company Email',
                    employeepresence: 'Employee Presence',
                    inegyptonly: 'In Egypt Only',
                    egyptandoutsideegypt: 'Egypt and Outside Egypt',
                    outsideegyptonly: 'Outside Egypt Only',
                    secondaryindustry: 'Secondary Industry',
                    shortdescription: 'Short Description',
                    longdescription: 'Long Description',
                    vision: 'Vision',
                    companycontactnumber: 'Company Contact Number',
                    professionalismduringtheprocess: 'Professionalism during the process',
                },
                ar: {},
            },
            langdetect: '',
            setlang: this.setlang,
        };
    }

    setlang = (key) => {
        const cookies = new Cookies();
        cookies.set('sitelang', key, { path: '/' });
        this.setState({
            langdetect: key,
        });
        window.location.reload();
    };

    componentDidMount() {
        const cookies = new Cookies();
        var lastlangselected = cookies.get('sitelang');
        if (lastlangselected == 'en') {
            this.setState({
                langdetect: 'en',
            });
        } else if (lastlangselected == 'ar') {
            this.setState({
                langdetect: 'en',
            });
            document.body.setAttribute('dir', 'rtl');
        } else {
            this.setState({
                langdetect: 'en',
            });
            document.body.setAttribute('dir', 'rtl');
            this.setlang('en');
        }
    }

    render() {
        var setlang = this.state.setlang;
        var lang = '';

        if (this.state.langdetect == 'en') {
            lang = this.state.lang.en;
            document.body.setAttribute('dir', 'ltr');
        } else if (this.state.langdetect == 'ar') {
            lang = this.state.lang.ar;
            document.body.setAttribute('dir', 'rtl');
        } else {
            lang = this.state.lang.ar;
            document.body.setAttribute('dir', 'rtl');
        }

        var langdetect = this.state.langdetect;
        return <LanguageContext.Provider value={{ lang, setlang, langdetect }}>{this.props.children}</LanguageContext.Provider>;
    }
}

export const LanguageContextConsumer = LanguageContext.Consumer;
