import React, { Suspense, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Loggedincontext } from './Loggedincontext.js';
import { Routedispatcherprovider } from './Routedispatcher';
import { IoMdClose } from 'react-icons/io';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './components/Website/Generalfiles/CSS_GENERAL/bootstrap.css';
import './components/Website/Generalfiles/CSS_GENERAL/dropdown.css';
import './trans.css';
import { Modal } from 'react-bootstrap';
import formstyles from './components/Website/Generalfiles/CSS_GENERAL/form.module.css';
import generalstyles from './components/Website/Generalfiles/CSS_GENERAL/general.module.css';
import { Contexthandlerscontext_provider } from './Contexthandlerscontext';
import { Contexthandlerscontext } from './Contexthandlerscontext.js';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';
// import pepsicologoresp from './components/Website/Generalfiles/images/pepsicologoresp.png';
import logo from './components/Website/Generalfiles/images/logo.png';
import Home from './components/Website/Home/Home.js';
import axios from 'axios';
import Cookies from 'universal-cookie';
import API from './API/API.js';
import { NotificationManager } from 'react-notifications';
import { useMutation } from 'react-query';
const Websiterouter = React.lazy(() => import('./components/Website/Websiterouter'));
const Login = React.lazy(() => import('./components/Website/Login/Login'));
const ApplicationSuccess = React.lazy(() => import('./components/Website/ApplicationSuccess/ApplicationSuccess'));

const App = (props) => {
    let history = useHistory();
    const { changePasswordMutation_API } = API();
    const { fetchAuthorizationQueryContext, openpasswordmodalContext, setopenpasswordmodalContext } = useContext(Loggedincontext);
    const [newcompanypasswordpayload, setnewcompanypasswordpayload] = useState({ password: '', confirmpassword: '' });

    axios.interceptors.request.use(function (config) {
        const cookies = new Cookies();

        var defaultheaders = config.headers;
        var token = cookies.get('coas12312efaasasdafasdas32131asdsadsadsaqweasdkjenfjenfk kern123!@_#!@3123');
        if (token != undefined) {
            defaultheaders.Authorization = 'Bearer ' + token;
        }

        config.headers = defaultheaders;
        return config;
    });
    const isadminandloggedin = () => {
        var access = false;
        if (fetchAuthorizationQueryContext?.data?.data?.loggedin == true) {
            access = true;
        }
        return access;
    };
    const ispending = () => {
        var access = false;
        if (
            fetchAuthorizationQueryContext?.data?.data?.userinfo?.user_profile?.emp_status == 'Pending' ||
            fetchAuthorizationQueryContext?.data?.data?.userinfo?.user_profile?.emp_status == 'Rejected'
        ) {
            access = true;
        }
        return access;
    };
    const changePasswordMutation = useMutation('changePasswordMutation_API', {
        mutationFn: changePasswordMutation_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.warning('', 'Error');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                // setopenModal(false);
                // history.push('/companies');
                NotificationManager.success('', 'Success');
                setopenpasswordmodalContext(false);
            } else {
                NotificationManager.warning(data.data.reason, 'Warning');
            }
        },
    });

    useEffect(() => {
        if (fetchAuthorizationQueryContext.isSuccess) {
            if (fetchAuthorizationQueryContext?.data?.data?.userinfo?.user_profile?.emp_isfirsttimepasswordchanged == 0) {
                if (fetchAuthorizationQueryContext?.data?.data?.userinfo?.user_profile?.emp_status == 'Accepted') {
                    setopenpasswordmodalContext(true);
                }
            }
        }
    }, [fetchAuthorizationQueryContext.isSuccess]);

    return (
        <Contexthandlerscontext_provider>
            <Contexthandlerscontext.Consumer>
                {(value) => {
                    return (
                        <>
                            {/* {value.fetchuseauthorizationQueryContext.isFetching && ( */}
                            {fetchAuthorizationQueryContext?.isFetching && (
                                <div style={{ height: '100vh' }} class="row w-100 allcentered m-0">
                                    <div class="col-lg-12 p-0">
                                        <div class="row m-0 w-100">
                                            <div class="col-lg-12 p-0 d-flex allcentered">
                                                <img style={{ objectFit: 'contain', widht: '15vh', height: '15vh' }} src={logo} />
                                            </div>
                                            <div class="col-lg-12 p-0 d-flex allcentered mt-3">
                                                <p class="font-weight-600">Loading...</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* )}
                            {/* {!value.fetchuseauthorizationQueryContext.isFetching && value.fetchuseauthorizationQueryContext.isSuccess && ( */}
                            {!fetchAuthorizationQueryContext?.isFetching && (
                                <Router>
                                    <Routedispatcherprovider>
                                        <div style={{ width: '100%' }}>
                                            <Suspense
                                                fallback={
                                                    <div style={{ height: '100vh' }} class="row w-100 allcentered m-0">
                                                        <div class="col-lg-12 p-0">
                                                            <div class="row m-0 w-100">
                                                                <div class="col-lg-12 p-0 d-flex allcentered">
                                                                    <img style={{ objectFit: 'contain', widht: '15vh', height: '15vh' }} src={logo} />
                                                                </div>
                                                                <div class="col-lg-12 p-0 d-flex allcentered mt-3">
                                                                    <p class="font-weight-600">Loading...</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                {isadminandloggedin() && !ispending() && <Websiterouter />}
                                                {isadminandloggedin() && ispending() && <ApplicationSuccess />}
                                                {!isadminandloggedin() && <Login />}

                                                {/* <Route
                                                render={({ location, match }) => {
                                                    return (
                                                        <TransitionGroup>
                                                            <Switch key={location.key} location={location}>
                                                                <Route
                                                                    exact
                                                                    path="/"
                                                                    render={(props) => {
                                                                        return <Redirect to={`/home`} />;
                                                                    }}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path="/home"
                                                                    render={() => {
                                                                        return <Home />;
                                                                    }}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path="/login"
                                                                    render={() => {
                                                                        return <Login />;
                                                                    }}
                                                                />

                                                                <Route
                                                                    exact
                                                                    path="/dashboard"
                                                                    render={() => {
                                                                        return;
                                                                    }}
                                                                />
                                                            </Switch>
                                                        </TransitionGroup>
                                                    );
                                                }}
                                            /> */}
                                            </Suspense>
                                            <Modal
                                                show={openpasswordmodalContext}
                                                onHide={() => {
                                                    if (fetchAuthorizationQueryContext?.data?.data?.userinfo?.user_profile?.emp_isfirsttimepasswordchanged == 1) {
                                                        setopenpasswordmodalContext(false);
                                                    }
                                                }}
                                                centered
                                                size={'lg'}
                                            >
                                                <Modal.Header>
                                                    {fetchAuthorizationQueryContext?.data?.data?.userinfo?.user_profile?.emp_isfirsttimepasswordchanged == 1 && (
                                                        <div className="row w-100 m-0 p-0">
                                                            <div class="col-lg-12 col-md-2 col-sm-2 d-flex align-items-center justify-content-end p-2">
                                                                <div
                                                                    class={'close-modal-container'}
                                                                    onClick={() => {
                                                                        setopenpasswordmodalContext(false);
                                                                    }}
                                                                >
                                                                    <IoMdClose />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className={'row m-0 w-100 allcentered'}>
                                                        <div class="col-lg-12 pt-0 pl-4 pr-4 pb-2 ">
                                                            <div class="col-lg-12 Carbona-Black p-0 " style={{ fontSize: '28px' }}>
                                                                Request for password change
                                                            </div>
                                                            <div class="col-lg-12 mb-0 ">
                                                                <div class={`${formstyles.form__group} ${formstyles.field}`}>
                                                                    <label for="password" class={formstyles.form__label}>
                                                                        New password
                                                                    </label>
                                                                    <input
                                                                        type="password"
                                                                        class={formstyles.form__field}
                                                                        value={newcompanypasswordpayload.password}
                                                                        onChange={(event) => {
                                                                            setnewcompanypasswordpayload({ ...newcompanypasswordpayload, password: event.target.value });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 mb-0 ">
                                                                <div class={`${formstyles.form__group} ${formstyles.field}`}>
                                                                    <label for="password" class={formstyles.form__label}>
                                                                        Confirm password
                                                                    </label>
                                                                    <input
                                                                        type="password"
                                                                        class={formstyles.form__field}
                                                                        value={newcompanypasswordpayload.confirmpassword}
                                                                        onChange={(event) => {
                                                                            setnewcompanypasswordpayload({ ...newcompanypasswordpayload, confirmpassword: event.target.value });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 mt-3 pr-3 pl-3 pb-3  d-flex justify-content-start">
                                                        <div class="row m-0 w-100  ">
                                                            <button
                                                                onClick={() => {
                                                                    if (newcompanypasswordpayload?.password?.length != 0 && newcompanypasswordpayload?.confirmpassword?.length != 0) {
                                                                        if (newcompanypasswordpayload?.password == newcompanypasswordpayload?.confirmpassword) {
                                                                            changePasswordMutation.mutate(newcompanypasswordpayload);
                                                                        } else {
                                                                            NotificationManager.warning('', "Passwords don't match");
                                                                        }
                                                                    }
                                                                }}
                                                                style={{
                                                                    backgroundColor:
                                                                        newcompanypasswordpayload?.password?.length == 0 || newcompanypasswordpayload?.confirmpassword?.length == 0 ? 'gray' : '',
                                                                    cursor:
                                                                        newcompanypasswordpayload?.password?.length == 0 || newcompanypasswordpayload?.confirmpassword?.length == 0
                                                                            ? 'not-allowed'
                                                                            : '',
                                                                }}
                                                                disabled={
                                                                    changePasswordMutation.isLoading ||
                                                                    newcompanypasswordpayload?.password?.length == 0 ||
                                                                    newcompanypasswordpayload?.confirmpassword?.length == 0
                                                                }
                                                                class={generalstyles.roundbutton + ' bg-info bg-infohover'}
                                                            >
                                                                {changePasswordMutation.isLoading && <CircularProgress color="white" width="15px" height="15px" duration="1s" />}
                                                                {!changePasswordMutation.isLoading && <span>Save</span>}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                    </Routedispatcherprovider>
                                </Router>
                            )}
                            {/* )} */}
                            <NotificationContainer />
                            <ReactQueryDevtools initialIsOpen />
                        </>
                    );
                }}
            </Contexthandlerscontext.Consumer>
        </Contexthandlerscontext_provider>
    );
};

export default App;
